*, ::after, ::before {
  box-sizing: border-box;
}

* {
  font-family: Arial;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#searchBox {
  display: flex;
  width: 300px;
  align-items: center;
  align-self: center;
}

#searchBar {
  border: none;
  border-bottom: 2px solid rgb(240, 240, 240);
  border-radius: 0;
  outline: none;
}

#searchBar:focus {
  border-bottom: 2px solid #FF5F1E;
}

a, button {
  cursor: pointer;
}

h1 {
  font-weight: normal;
  font-size: 56px;
}

.modalh1 {
  margin-top: 10px;
}

.flex-container {
  display: flex; 
}

.flex-child-auto {
  flex: 1 1 auto; 
}

.flex-child-grow {
  flex: 1 0 auto; 
}

.flex-child-shrink {
  flex: 0 1 auto; 
}

.flex-dir-row {
  flex-direction: row; 
}

.flex-dir-row-reverse {
  flex-direction: row-reverse; 
}

.flex-dir-column {
  flex-direction: column; 
}

.flex-dir-column-reverse {
  flex-direction: column-reverse; 
}

nav ul {
  margin: 0;
  padding: 0;
}

#statusField {
  font-family: 'Segoe UI', Tahoma, Verdana, sans-serif !important;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-Content: center;
  align-Items: center;
  font-Size: 12px;            
  border-Radius: 0 20px 20px 0;
  max-Width: 70px;
  min-Width: 70px;
}

#bookingstatus {
  font-family: 'Segoe UI', Tahoma, Verdana, sans-serif !important;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-Content: center;
  align-Items: center;
  font-Size: 12px;            
  border-Radius: 0 20px 20px 0;
  max-Width: 180px;
  min-Width: 180px;
}

#bookingstatusbox {
  background: #E8E8E8;
  display: flex;
  width: 320px;
  height: 160px;
  justify-content: space-between;                       
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 4px 4px 9px -6px;
}

.awaiting {
  background: #F3AB23;
}
.ready {
  background: #42CB6B;
  color: #FFFFFF
}
.ongoing {
  background: #008DFF;
  color: #FFFFFF
}
.done {
  background: #777777;
  color: #FFFFFF
}
.rejected {
  background: #DF4848;
  color: #FFFFFF
}

#logout {
  background: #1A1B4E
}

a:hover {
  opacity: 0.8;
}

.bookingBox {  
  background: #E8E8E8;
  display: flex;
  width: 360px;
  height: 200px;
  justify-content: space-between;                       
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 4px 4px 9px -6px;
  margin: 25px
}

.bookingBoxDone {
  background: #E8E8E8;
  display: flex;
  width: 360px;
  height: 70px;
  justify-content: space-between;                       
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 4px 4px 9px -6px;
  margin: 5px 25px 5px 25px;
}

.wrapper {
  display: flex;
  padding: 30px;
  padding-top: 0;
  flex-Wrap: wrap;
  justify-Content: center;
  flex: 9;
}

.bookingBox:hover, .bookingBoxDone:hover {
  background: #c7c7c7;
}

/* LOGIN FORM STYLING */

/* Full-width inputs */
input[type=text], input[type=password], input[type=date], select, textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font: 400 13.3333px Verdana;
  border-radius: 10px;
}

/* Set a style for all buttons */
button {
  background-color: #171B60;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

.buttons {
  background: #191A4E;
  color: white;
  padding: 20px;
  display: flex;
  justify-Content: center;
  align-Items: center;
  border-Radius: 6px;
  cursor: pointer;
}

#backbuttonbook {
  align-self: flex-start;
  padding: 8px 40px 8px 40px;
  font-size: 24px;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  justify-content: center;
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
}

/* Modal Content/Box */
#confirmmodal-content {
  margin: 15% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 400px; /* Could be more or less, depending on screen size */
  border-radius: 24px;
  background: rgb(245, 245, 245);
  height: 165px;
}

/* Modal Content/Box */
#bookmodal-content {
  display: flex;
  height: 800px;
  margin: 5% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  width: 500px; /* Could be more or less, depending on screen size */
  border-radius: 8px;
  background: rgb(245, 245, 245);
}

.modalbuttonbox {
  width: fit-content;
  margin: auto;
}

.cancelmodalbutton {
  margin-right: 5px;
  margin-top: 8px;
  width: 140px;
  height: 36px;
  border-radius: 10px;
}

#cancelremovebookmodal {
  background: white;
  border: solid orange 1px;
  color: orange;
  padding: 0;
}

.bookmodalbutton {
  margin-top: 8px;
  width: 180px;
  border: solid #1A1B4E 1px;
  height: 36px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 0;
}

#bookmodalbutton {
  border: solid #1A1B4E 1px;
  color: white;
  padding: 0;
}

.removemodalbutton {
  margin-top: 8px;
  color: #DF4848;
  width: 180px;
  border: solid #DF4848 1px;
  height: 36px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 0;
}

#removebookmodalbutton {
  color: white;
  background: #DF4848;
}

.modalforms {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: left;
}

#deletebuttonbook {
  align-self: flex-start;
  background: white;
  border: 2px solid #DF4848;
  color: #DF4848;
  padding: 8px 40px 8px 40px;
  font-size: 24px;
}

#backbuttonboxes {
  padding: 12px 50px 12px 50px;
  font-size: 28px;
}

#backbuttonclient {
  align-self: flex-start;
  padding: 8px 40px 8px 40px;
  font-size: 24px;
}

#boxbuttons {
  justify-content: space-between;
  display: flex;
  padding: 30px;
  flex-direction: row;
}

#createbuttonboxes {
  padding: 12px 50px 12px 50px;
  font-size: 28px;
}

/* Add a hover effect for buttons */
button:hover {
  opacity: 0.8;
}

/* Extra style for the cancel button (red) */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: #f44336;
}

#addclient {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 50px 0;
  width: 1300px;
}

/* Center the avatar image inside this container */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

/* Add padding to containers */
.container {
  padding: 16px;
}

/* The "Forgot password" text */
span.psw {
  float: right;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
      display: block;
      float: none;
  }
  .cancelbtn {
      width: 100%;
  }
}

.flexCenteredColumn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flexCentered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectionBox {
  box-shadow: 3px 3px grey;
  width: 270px;
  height: 150px;
  margin: 10px;
  background: #1f2464;
  font-size: 25px;
}


.spinningicon {
  animation-name: twirl;
  animation-duration: 2s;
}

@keyframes twirl {
  0% {
    transform: rotate(0deg)
  }

  50% {
    transform: rotate(375deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

/* TABLE STYLING */

table.minimalistBlack {
  width: 85%;
  text-align: left;
  border-collapse: collapse;
  margin: 0 auto;
}

table.minimalistBlack td, table.minimalistBlack th {
  border: 1px solid #000000;
  padding: 13px;
}

table.minimalistBlack tbody tr:hover td:not(:nth-last-child(1)) {
  background-color: rgb(207, 207, 207);
  cursor: pointer;
}

table.minimalistBlack tbody td {
  font-size: 13px;
}

table.minimalistBlack thead {
  background: #1f2464;
  background: -moz-linear-gradient(top, #1f2464 0%, #1f2464 66%, #1f2464 100%);
  background: -webkit-linear-gradient(top, #1f2464 0%, #1f2464 66%, #1f2464 100%);
  background: linear-gradient(to bottom, #1f2464 0%, #1f2464 66%, #1f2464 100%);
  border-bottom: 3px solid #000000;
}

table.minimalistBlack thead th {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  text-align: left;
}

table.minimalistBlack tfoot td {
  font-size: 14px;
}

div.scroll {
    padding: 4px;
    height: 200px;
    width: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background: #E8E8E8;
    border-radius: 10px;
    border-top: 2px solid #cfcfcf;
    border-left: 2px solid #cfcfcf;
}

/* width */
.scroll::-webkit-scrollbar {
  width: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E8E8E8; 
  border-radius: 0 10px 10px 0;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.horizontal {
  border-right: 2px solid;
}

.last {
  border-right: none;
}

#mainimg {
  width: 400px;
}

.deleteButton:hover {
  background: #ff7676;
}

.deleteButton {   
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin: 0 auto;
  cursor: pointer;
  
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.footer {
  background: #131D4C;
  padding-bottom: 23px; 
}

.grid-container {
  padding-right: 1.58192rem;
  padding-left: 1.58192rem;
  max-width: 59.66102rem;
  margin-left: auto;
  margin-right: auto; 
}

.grid-x {
  display: flex;
  flex-flow: row wrap; 
}

.grid-margin-x {
  margin-left: -0.56497rem;
  margin-right: -0.56497rem; 
}

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%; 
}

.cell.auto {
  flex: 1 1 0px; 
}
.cell.shrink {
  flex: 0 0 auto; 
}

.footer-column-title {
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  padding-bottom: 6px;
  position: relative;
  margin-bottom: 10px; 
}

.footer-item-list {
  margin-bottom: 20px; 
}

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; 
}

.footer-item-list__item {
  color: #ffffff;
  font-size: 13px;
  line-height: 24px; 
}

.list-base {
  display: flex;
  flex-direction: row;
  list-style: none;
  flex-wrap: wrap;
  margin: 0; 
}

.list-base--vertical {
  flex-direction: column;
  flex-wrap: nowrap; 
}

.logo-footer {
  display: block;
  width: 115px;
  transform: translateY(-4px); 
}

.logo {
  display: block;
  margin-top: -3px; 
}
.logo img {
  width: 138.03px; 
}

.footer-end {
  font-size: 9px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 20px; 
}

.align-justify {
  justify-content: space-between; 
}

.footer-end__link {
  color: inherit; 
}

.footer-end__tagline {
  color: #FF5F1E;
  font-size: 15.8px;
  letter-spacing: 0.2px;
  margin-left: -1px;
  display: flex;
  white-space: nowrap; 
}

.align-left {
  justify-content: flex-start; 
}

.align-right {
  justify-content: flex-end; 
}

.align-center {
  justify-content: center; 
}

.align-justify {
  justify-content: space-between; 
}

.align-spaced {
  justify-content: space-around; 
}

.align-left.vertical.menu > li > a {
  justify-content: flex-start; 
}

.align-right.vertical.menu > li > a {
  justify-content: flex-end; 
}

.align-center.vertical.menu > li > a {
  justify-content: center; 
}

.align-top {
  align-items: flex-start; 
}

.align-self-top {
  align-self: flex-start; 
}

.align-bottom {
  align-items: flex-end; 
}

.align-self-bottom {
  align-self: flex-end; 
}

.align-middle {
  align-items: center; 
}

.align-self-middle {
  align-self: center; 
}

.align-stretch {
  align-items: stretch; 
}

.align-self-stretch {
  align-self: stretch; 
}

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; 
}

.service-nav {
  margin-left: -19px;
  margin-right: 41px;
  margin-top: 2px;
}

.service-nav__link {
  padding-left: 19px;
  padding-right: 19px;
  font-weight: 500;
  color: #ffffff;
  font-size: 19px;
  position: relative; 
}

.service-nav__item:hover .service-nav__link,
.service-nav__item.current .service-nav__link,
.service-nav__item.section .service-nav__link {
  color: #FF5F1E; 
}

.service-nav__item:hover .service-nav__link::before, .service-nav__item:hover .service-nav__link::after,
.service-nav__item.current .service-nav__link::before,
.service-nav__item.current .service-nav__link::after,
.service-nav__item.section .service-nav__link::before,
.service-nav__item.section .service-nav__link::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%); 
}

.service-nav__item:hover .service-nav__link::before,
.service-nav__item.current .service-nav__link::before,
.service-nav__item.section .service-nav__link::before {
  height: 2px;
  background-color: #FF5F1E;
  bottom: -32px;
  width: calc(100% - 38px); 
}

.service-nav__item:hover .service-nav__link::after,
.service-nav__item.current .service-nav__link::after,
.service-nav__item.section .service-nav__link::after {
  height: 36px;
  position: absolute;
  top: 100%;
  z-index: 1;
  width: 100%; 
}

.service-nav__children {
  position: absolute;
  top: 100%;
  right: 0;
  border-radius: 13px;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.03);
  min-width: 100%;
  z-index: 1;
  padding: 35px 51px 36px 60px;
  font-size: 12px;
  line-height: 17px;
  color: #131D4C;
  display: none;
  margin-top: 31px; 
}

.service-nav__link:hover + .service-nav__children, .service-nav__children:hover {
  display: flex; 
}

.service-nav__children-text {
  width: 170px;
  height: 100%;
  position: relative;
  padding-left: 29px;
  margin-left: 44px; 
}

.service-nav__children-text::before {
  content: '';
  width: 2px;
  height: 100%;
  background-color: #131D4C;
  position: absolute;
  left: 0;
  top: 0; 
}

.service-nav__children-text p a {
  color: #FF5F1E; 
}

.service-nav h2, .service-nav__sub-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  margin-top: 1px;
  color: #131D4C;
  margin-bottom: 21px; 
}

.service-nav .child-nav {
  list-style: none;
  column-gap: 40px;
  column-count: 2;
  margin: 0; 
}

.service-nav .child-nav__item {
  margin-bottom: 16px; 
}

.service-nav .child-nav__link {
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 40px;
  white-space: nowrap;
  color: #131D4C;
  position: relative; 
}

.service-nav .child-nav__link:hover, .service-nav .child-nav__link.current {
  color: #FF5F1E; 
}

.service-nav .child-nav__icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 36px; 
}

.mobile-nav {
  display: block;
  margin-top: -2px; }
@media print, screen and (min-width: 40em) {
  .mobile-nav {
    margin-top: 0; 
  } 
}
.mobile-nav span.hamburger-wrapper {
  width: 36px;
  height: 30px;
  display: block;
  position: relative;
  padding: 5px; 
}
.mobile-nav span.hamburger {
  width: 32px;
  height: 3px;
  background-color: #FF5F1E;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); 
}
.mobile-nav span.hamburger::before, .mobile-nav span.hamburger::after {
  content: '';
  height: inherit;
  background-color: inherit;
  position: absolute;
  right: 0; 
}
.mobile-nav span.hamburger::before {
  width: inherit;
  top: -10px; 
}
.mobile-nav span.hamburger::after {
  width: 23px;
  bottom: -10px; 
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
  margin: 0;
  padding: 0;
  border: 0;
}

.hide {
  display: none !important; 
}

#bookingDateText {
  width: fit-content;
}

#bookingDateSubmit {
  display: none;
}

.EditPen {
  height: 32px;
  align-self: center;
  padding-left: 20px;
}

#bookingExpandInput {
  width: 500px;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 24px;
  resize: none;
}

#sortButton {
  background: none;
  color: black;
  display: flex;
  align-items: center;
  font-size: 18px;
  padding: 0 !important;
}

#sortBoxes {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  width: 500px;
  align-self: flex-end;
}

#sortBoxes button {
  padding: 8px;
  background: none;
  color: #171B60;
  border: 1px solid #171B60;
  font-size: 16px;
}

#sortBoxes button.active{
  background-color: #171B60;
  color: white;
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 11px 34px 10px;
  border: 1px solid transparent;
  border-radius: 21px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-family: "Jost", Roboto, Arial, sans-serif;
  font-size: 0.66667rem;
  -webkit-appearance: none;
  line-height: 1;
  text-align: center;
  cursor: pointer; 
}
[data-whatinput='mouse'] .button {
  outline: 0; 
}
.button.tiny {
  font-size: 0.50847rem; 
}
.button.small {
  font-size: 0.56497rem; 
}
.button.large {
  font-size: 0.73446rem; 
}
.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0; 
}
.button, .button.disabled, .button[disabled], .button.disabled:hover, .button[disabled]:hover, .button.disabled:focus, .button[disabled]:focus {
  background-color: #FF5F1E;
  color: #ffffff; 
}
.button:hover, .button:focus {
  background-color: #f24600;
  color: #ffffff; 
}
.button.primary, .button.primary.disabled, .button.primary[disabled], .button.primary.disabled:hover, .button.primary[disabled]:hover, .button.primary.disabled:focus, .button.primary[disabled]:focus {
  background-color: #131D4C;
  color: #ffffff; 
}
.button.primary:hover, .button.primary:focus {
  background-color: #0f173d;
  color: #ffffff; 
}
.button.secondary, .button.secondary.disabled, .button.secondary[disabled], .button.secondary.disabled:hover, .button.secondary[disabled]:hover, .button.secondary.disabled:focus, .button.secondary[disabled]:focus {
  background-color: #FF5F1E;
  color: #000000; 
}
.button.secondary:hover, .button.secondary:focus {
  background-color: #e44200;
  color: #000000; 
}
.button.tertiary, .button.tertiary.disabled, .button.tertiary[disabled], .button.tertiary.disabled:hover, .button.tertiary[disabled]:hover, .button.tertiary.disabled:focus, .button.tertiary[disabled]:focus {
  background-color: #787887;
  color: #000000;
 }
.button.tertiary:hover, .button.tertiary:focus {
  background-color: #60606c;
  color: #000000; 
}
.button.quaternary, .button.quaternary.disabled, .button.quaternary[disabled], .button.quaternary.disabled:hover, .button.quaternary[disabled]:hover, .button.quaternary.disabled:focus, .button.quaternary[disabled]:focus {
  background-color: #787887;
  color: #000000; 
}
.button.quaternary:hover, .button.quaternary:focus {
  background-color: #60606c;
  color: #000000; 
}
.button.neutral, .button.neutral.disabled, .button.neutral[disabled], .button.neutral.disabled:hover, .button.neutral[disabled]:hover, .button.neutral.disabled:focus, .button.neutral[disabled]:focus {
  background-color: #2E384D;
  color: #ffffff; 
}
.button.neutral:hover, .button.neutral:focus {
  background-color: #252d3e;
  color: #ffffff; 
}
.button.success, .button.success.disabled, .button.success[disabled], .button.success.disabled:hover, .button.success[disabled]:hover, .button.success.disabled:focus, .button.success[disabled]:focus {
  background-color: #82D9A3;
  color: #000000; 
}
.button.success:hover, .button.success:focus {
  background-color: #4dc97c;
  color: #000000; 
}
.button.warning, .button.warning.disabled, .button.warning[disabled], .button.warning.disabled:hover, .button.warning[disabled]:hover, .button.warning.disabled:focus, .button.warning[disabled]:focus {
  background-color: #FFE574;
  color: #000000; 
}
.button.warning:hover, .button.warning:focus {
  background-color: #ffd72a;
  color: #000000; 
}
.button.alert, .button.alert.disabled, .button.alert[disabled], .button.alert.disabled:hover, .button.alert[disabled]:hover, .button.alert.disabled:focus, .button.alert[disabled]:focus {
  background-color: #FF6A6A;
  color: #000000; 
}
.button.alert:hover, .button.alert:focus {
  background-color: #ff2222;
  color: #000000; 
}
.button.info, .button.info.disabled, .button.info[disabled], .button.info.disabled:hover, .button.info[disabled]:hover, .button.info.disabled:focus, .button.info[disabled]:focus {
  background-color: #4689F4;
  color: #000000; 
}
.button.info:hover, .button.info:focus {
  background-color: #0e64ed;
  color: #000000; 
}
.button.hollow, .button.hollow:hover, .button.hollow:focus, .button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
  background-color: transparent; 
}
.button.hollow, .button.hollow.disabled, .button.hollow[disabled], .button.hollow.disabled:hover, .button.hollow[disabled]:hover, .button.hollow.disabled:focus, .button.hollow[disabled]:focus {
  border: 1px solid #FF5F1E;
  color: #FF5F1E; 
}
.button.hollow:hover, .button.hollow:focus {
  border-color: #c83a00;
  color: #c83a00; 
}
.button.hollow.primary, .button.hollow.primary.disabled, .button.hollow.primary[disabled], .button.hollow.primary.disabled:hover, .button.hollow.primary[disabled]:hover, .button.hollow.primary.disabled:focus, .button.hollow.primary[disabled]:focus {
  border: 1px solid #131D4C;
  color: #131D4C; 
}
.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: #0d1435;
  color: #0d1435; 
}
.button.hollow.secondary, .button.hollow.secondary.disabled, .button.hollow.secondary[disabled], .button.hollow.secondary.disabled:hover, .button.hollow.secondary[disabled]:hover, .button.hollow.secondary.disabled:focus, .button.hollow.secondary[disabled]:focus {
  border: 1px solid #FF5F1E;
  color: #FF5F1E; 
}
.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #c83a00;
  color: #c83a00; 
}
.button.hollow.tertiary, .button.hollow.tertiary.disabled, .button.hollow.tertiary[disabled], .button.hollow.tertiary.disabled:hover, .button.hollow.tertiary[disabled]:hover, .button.hollow.tertiary.disabled:focus, .button.hollow.tertiary[disabled]:focus {
  border: 1px solid #787887;
  color: #787887; 
}
.button.hollow.tertiary:hover, .button.hollow.tertiary:focus {
  border-color: #54545f;
  color: #54545f; 
}
.button.hollow.quaternary, .button.hollow.quaternary.disabled, .button.hollow.quaternary[disabled], .button.hollow.quaternary.disabled:hover, .button.hollow.quaternary[disabled]:hover, .button.hollow.quaternary.disabled:focus, .button.hollow.quaternary[disabled]:focus {
  border: 1px solid #787887;
  color: #787887; 
}
.button.hollow.quaternary:hover, .button.hollow.quaternary:focus {
  border-color: #54545f;
  color: #54545f; 
}
.button.hollow.neutral, .button.hollow.neutral.disabled, .button.hollow.neutral[disabled], .button.hollow.neutral.disabled:hover, .button.hollow.neutral[disabled]:hover, .button.hollow.neutral.disabled:focus, .button.hollow.neutral[disabled]:focus {
  border: 1px solid #2E384D;
  color: #2E384D; 
}
.button.hollow.neutral:hover, .button.hollow.neutral:focus {
  border-color: #202736;
  color: #202736; 
}
.button.hollow.success, .button.hollow.success.disabled, .button.hollow.success[disabled], .button.hollow.success.disabled:hover, .button.hollow.success[disabled]:hover, .button.hollow.success.disabled:focus, .button.hollow.success[disabled]:focus {
  border: 1px solid #82D9A3;
  color: #82D9A3; 
}
.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #39ba6a;
  color: #39ba6a; 
}
.button.hollow.warning, .button.hollow.warning.disabled, .button.hollow.warning[disabled], .button.hollow.warning.disabled:hover, .button.hollow.warning[disabled]:hover, .button.hollow.warning.disabled:focus, .button.hollow.warning[disabled]:focus {
  border: 1px solid #FFE574;
  color: #FFE574;
}
.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #ffd005;
  color: #ffd005; 
}
.button.hollow.alert, .button.hollow.alert.disabled, .button.hollow.alert[disabled], .button.hollow.alert.disabled:hover, .button.hollow.alert[disabled]:hover, .button.hollow.alert.disabled:focus, .button.hollow.alert[disabled]:focus {
  border: 1px solid #FF6A6A;
  color: #FF6A6A; 
}
.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #fd0000;
  color: #fd0000; 
}
.button.hollow.info, .button.hollow.info.disabled, .button.hollow.info[disabled], .button.hollow.info.disabled:hover, .button.hollow.info[disabled]:hover, .button.hollow.info.disabled:focus, .button.hollow.info[disabled]:focus {
  border: 1px solid #4689F4;
  color: #4689F4; 
}
.button.hollow.info:hover, .button.hollow.info:focus {
  border-color: #0c57cf;
  color: #0c57cf; 
}
.button.clear, .button.clear:hover, .button.clear:focus, .button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
  border-color: transparent;
  background-color: transparent; 
}
.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear.disabled:hover, .button.clear[disabled]:hover, .button.clear.disabled:focus, .button.clear[disabled]:focus {
  color: #FF5F1E; 
}
.button.clear:hover, .button.clear:focus {
  color: #c83a00; 
}
.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary.disabled:hover, .button.clear.primary[disabled]:hover, .button.clear.primary.disabled:focus, .button.clear.primary[disabled]:focus {
  color: #131D4C; 
}
.button.clear.primary:hover, .button.clear.primary:focus {
  color: #0d1435; 
}
.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary.disabled:hover, .button.clear.secondary[disabled]:hover, .button.clear.secondary.disabled:focus, .button.clear.secondary[disabled]:focus {
  color: #FF5F1E; 
}
.button.clear.secondary:hover, .button.clear.secondary:focus {
  color: #c83a00; 
}
.button.clear.tertiary, .button.clear.tertiary.disabled, .button.clear.tertiary[disabled], .button.clear.tertiary.disabled:hover, .button.clear.tertiary[disabled]:hover, .button.clear.tertiary.disabled:focus, .button.clear.tertiary[disabled]:focus {
  color: #787887; 
}
.button.clear.tertiary:hover, .button.clear.tertiary:focus {
  color: #54545f; 
}
.button.clear.quaternary, .button.clear.quaternary.disabled, .button.clear.quaternary[disabled], .button.clear.quaternary.disabled:hover, .button.clear.quaternary[disabled]:hover, .button.clear.quaternary.disabled:focus, .button.clear.quaternary[disabled]:focus {
  color: #787887; 
}
.button.clear.quaternary:hover, .button.clear.quaternary:focus {
  color: #54545f; 
}
.button.clear.neutral, .button.clear.neutral.disabled, .button.clear.neutral[disabled], .button.clear.neutral.disabled:hover, .button.clear.neutral[disabled]:hover, .button.clear.neutral.disabled:focus, .button.clear.neutral[disabled]:focus {
  color: #2E384D; 
}
.button.clear.neutral:hover, .button.clear.neutral:focus {
  color: #202736; 
}
.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success.disabled:hover, .button.clear.success[disabled]:hover, .button.clear.success.disabled:focus, .button.clear.success[disabled]:focus {
  color: #82D9A3; 
}
.button.clear.success:hover, .button.clear.success:focus {
  color: #39ba6a; 
}
.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning.disabled:hover, .button.clear.warning[disabled]:hover, .button.clear.warning.disabled:focus, .button.clear.warning[disabled]:focus {
  color: #FFE574; 
}
.button.clear.warning:hover, .button.clear.warning:focus {
  color: #ffd005; 
}
.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert.disabled:hover, .button.clear.alert[disabled]:hover, .button.clear.alert.disabled:focus, .button.clear.alert[disabled]:focus {
  color: #FF6A6A; 
}
.button.clear.alert:hover, .button.clear.alert:focus {
  color: #fd0000; 
}
.button.clear.info, .button.clear.info.disabled, .button.clear.info[disabled], .button.clear.info.disabled:hover, .button.clear.info[disabled]:hover, .button.clear.info.disabled:focus, .button.clear.info[disabled]:focus {
  color: #4689F4; 
}
.button.clear.info:hover, .button.clear.info:focus {
  color: #0c57cf; 
}
.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed; 
}
.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #ffffff transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  margin-left: 34px; 
}
.button.dropdown.hollow::after, .button.dropdown.clear::after {
  border-top-color: #FF5F1E; 
}
.button.dropdown.hollow.primary::after, .button.dropdown.clear.primary::after {
  border-top-color: #131D4C; 
}
.button.dropdown.hollow.secondary::after, .button.dropdown.clear.secondary::after {
  border-top-color: #FF5F1E; 
}
.button.dropdown.hollow.tertiary::after, .button.dropdown.clear.tertiary::after {
  border-top-color: #787887; 
}
.button.dropdown.hollow.quaternary::after, .button.dropdown.clear.quaternary::after {
  border-top-color: #787887; 
}
.button.dropdown.hollow.neutral::after, .button.dropdown.clear.neutral::after {
  border-top-color: #2E384D; 
}
.button.dropdown.hollow.success::after, .button.dropdown.clear.success::after {
  border-top-color: #82D9A3; 
}
.button.dropdown.hollow.warning::after, .button.dropdown.clear.warning::after {
  border-top-color: #FFE574; 
}
.button.dropdown.hollow.alert::after, .button.dropdown.clear.alert::after {
  border-top-color: #FF6A6A; 
}
.button.dropdown.hollow.info::after, .button.dropdown.clear.info::after {
  border-top-color: #4689F4; 
}
.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0; 
}

a.button:hover, a.button:focus {
  text-decoration: none; 
}

.grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex: 0 0 auto; }

.grid-x > .small-1 {
  width: 8.33333%; }

.grid-x > .small-2 {
  width: 16.66667%; }

.grid-x > .small-3 {
  width: 25%; }

.grid-x > .small-4 {
  width: 33.33333%; }

.grid-x > .small-5 {
  width: 41.66667%; }

.grid-x > .small-6 {
  width: 50%; }

.grid-x > .small-7 {
  width: 58.33333%; }

.grid-x > .small-8 {
  width: 66.66667%; }

.grid-x > .small-9 {
  width: 75%; }

.grid-x > .small-10 {
  width: 83.33333%; }

.grid-x > .small-11 {
  width: 91.66667%; }

.grid-x > .small-12 {
  width: 100%; }

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .medium-shrink, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex: 0 0 auto; }
  .grid-x > .medium-shrink {
    width: auto; }
  .grid-x > .medium-1 {
    width: 8.33333%; }
  .grid-x > .medium-2 {
    width: 16.66667%; }
  .grid-x > .medium-3 {
    width: 25%; }
  .grid-x > .medium-4 {
    width: 33.33333%; }
  .grid-x > .medium-5 {
    width: 41.66667%; }
  .grid-x > .medium-6 {
    width: 50%; }
  .grid-x > .medium-7 {
    width: 58.33333%; }
  .grid-x > .medium-8 {
    width: 66.66667%; }
  .grid-x > .medium-9 {
    width: 75%; }
  .grid-x > .medium-10 {
    width: 83.33333%; }
  .grid-x > .medium-11 {
    width: 91.66667%; }
  .grid-x > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto; }
  .grid-x > .large-shrink, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex: 0 0 auto; }
  .grid-x > .large-shrink {
    width: auto; }
  .grid-x > .large-1 {
    width: 8.33333%; }
  .grid-x > .large-2 {
    width: 16.66667%; }
  .grid-x > .large-3 {
    width: 25%; }
  .grid-x > .large-4 {
    width: 33.33333%; }
  .grid-x > .large-5 {
    width: 41.66667%; }
  .grid-x > .large-6 {
    width: 50%; }
  .grid-x > .large-7 {
    width: 58.33333%; }
  .grid-x > .large-8 {
    width: 66.66667%; }
  .grid-x > .large-9 {
    width: 75%; }
  .grid-x > .large-10 {
    width: 83.33333%; }
  .grid-x > .large-11 {
    width: 91.66667%; }
  .grid-x > .large-12 {
    width: 100%; } }

.grid-margin-x:not(.grid-x) > .cell {
  width: auto; }

.grid-margin-y:not(.grid-y) > .cell {
  height: auto; }

.grid-margin-x {
  margin-left: -0.56497rem;
  margin-right: -0.56497rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x {
      margin-left: -0.90395rem;
      margin-right: -0.90395rem; } }
  .grid-margin-x > .cell {
    width: calc(100% - 1.12994rem);
    margin-left: 0.56497rem;
    margin-right: 0.56497rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
      width: calc(100% - 1.80791rem);
      margin-left: 0.90395rem;
      margin-right: 0.90395rem; } }
  .grid-margin-x > .auto {
    width: auto; }
  .grid-margin-x > .shrink {
    width: auto; }
  .grid-margin-x > .small-1 {
    width: calc(8.33333% - 1.12994rem); }
  .grid-margin-x > .small-2 {
    width: calc(16.66667% - 1.12994rem); }
  .grid-margin-x > .small-3 {
    width: calc(25% - 1.12994rem); }
  .grid-margin-x > .small-4 {
    width: calc(33.33333% - 1.12994rem); }
  .grid-margin-x > .small-5 {
    width: calc(41.66667% - 1.12994rem); }
  .grid-margin-x > .small-6 {
    width: calc(50% - 1.12994rem); }
  .grid-margin-x > .small-7 {
    width: calc(58.33333% - 1.12994rem); }
  .grid-margin-x > .small-8 {
    width: calc(66.66667% - 1.12994rem); }
  .grid-margin-x > .small-9 {
    width: calc(75% - 1.12994rem); }
  .grid-margin-x > .small-10 {
    width: calc(83.33333% - 1.12994rem); }
  .grid-margin-x > .small-11 {
    width: calc(91.66667% - 1.12994rem); }
  .grid-margin-x > .small-12 {
    width: calc(100% - 1.12994rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
      width: auto; }
    .grid-margin-x > .shrink {
      width: auto; }
    .grid-margin-x > .small-1 {
      width: calc(8.33333% - 1.80791rem); }
    .grid-margin-x > .small-2 {
      width: calc(16.66667% - 1.80791rem); }
    .grid-margin-x > .small-3 {
      width: calc(25% - 1.80791rem); }
    .grid-margin-x > .small-4 {
      width: calc(33.33333% - 1.80791rem); }
    .grid-margin-x > .small-5 {
      width: calc(41.66667% - 1.80791rem); }
    .grid-margin-x > .small-6 {
      width: calc(50% - 1.80791rem); }
    .grid-margin-x > .small-7 {
      width: calc(58.33333% - 1.80791rem); }
    .grid-margin-x > .small-8 {
      width: calc(66.66667% - 1.80791rem); }
    .grid-margin-x > .small-9 {
      width: calc(75% - 1.80791rem); }
    .grid-margin-x > .small-10 {
      width: calc(83.33333% - 1.80791rem); }
    .grid-margin-x > .small-11 {
      width: calc(91.66667% - 1.80791rem); }
    .grid-margin-x > .small-12 {
      width: calc(100% - 1.80791rem); }
    .grid-margin-x > .medium-auto {
      width: auto; }
    .grid-margin-x > .medium-shrink {
      width: auto; }
    .grid-margin-x > .medium-1 {
      width: calc(8.33333% - 1.80791rem); }
    .grid-margin-x > .medium-2 {
      width: calc(16.66667% - 1.80791rem); }
    .grid-margin-x > .medium-3 {
      width: calc(25% - 1.80791rem); }
    .grid-margin-x > .medium-4 {
      width: calc(33.33333% - 1.80791rem); }
    .grid-margin-x > .medium-5 {
      width: calc(41.66667% - 1.80791rem); }
    .grid-margin-x > .medium-6 {
      width: calc(50% - 1.80791rem); }
    .grid-margin-x > .medium-7 {
      width: calc(58.33333% - 1.80791rem); }
    .grid-margin-x > .medium-8 {
      width: calc(66.66667% - 1.80791rem); }
    .grid-margin-x > .medium-9 {
      width: calc(75% - 1.80791rem); }
    .grid-margin-x > .medium-10 {
      width: calc(83.33333% - 1.80791rem); }
    .grid-margin-x > .medium-11 {
      width: calc(91.66667% - 1.80791rem); }
    .grid-margin-x > .medium-12 {
      width: calc(100% - 1.80791rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
      width: auto; }
    .grid-margin-x > .large-shrink {
      width: auto; }
    .grid-margin-x > .large-1 {
      width: calc(8.33333% - 1.80791rem); }
    .grid-margin-x > .large-2 {
      width: calc(16.66667% - 1.80791rem); }
    .grid-margin-x > .large-3 {
      width: calc(25% - 1.80791rem); }
    .grid-margin-x > .large-4 {
      width: calc(33.33333% - 1.80791rem); }
    .grid-margin-x > .large-5 {
      width: calc(41.66667% - 1.80791rem); }
    .grid-margin-x > .large-6 {
      width: calc(50% - 1.80791rem); }
    .grid-margin-x > .large-7 {
      width: calc(58.33333% - 1.80791rem); }
    .grid-margin-x > .large-8 {
      width: calc(66.66667% - 1.80791rem); }
    .grid-margin-x > .large-9 {
      width: calc(75% - 1.80791rem); }
    .grid-margin-x > .large-10 {
      width: calc(83.33333% - 1.80791rem); }
    .grid-margin-x > .large-11 {
      width: calc(91.66667% - 1.80791rem); }
    .grid-margin-x > .large-12 {
      width: calc(100% - 1.80791rem); } }

.grid-padding-x .grid-padding-x {
  margin-right: -0.56497rem;
  margin-left: -0.56497rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
      margin-right: -0.90395rem;
      margin-left: -0.90395rem; } }

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.56497rem;
  margin-left: -0.56497rem; }
  @media print, screen and (min-width: 40em) {
    .grid-container:not(.full) > .grid-padding-x {
      margin-right: -0.90395rem;
      margin-left: -0.90395rem; } }

.grid-padding-x > .cell {
  padding-right: 0.56497rem;
  padding-left: 0.56497rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
      padding-right: 0.90395rem;
      padding-left: 0.90395rem; } }

.small-up-1 > .cell {
  width: 100%; }

.small-up-2 > .cell {
  width: 50%; }

.small-up-3 > .cell {
  width: 33.33333%; }

.small-up-4 > .cell {
  width: 25%; }

.small-up-5 > .cell {
  width: 20%; }

.small-up-6 > .cell {
  width: 16.66667%; }

.small-up-7 > .cell {
  width: 14.28571%; }

.small-up-8 > .cell {
  width: 12.5%; }

@media print, screen and (min-width: 40em) {
  .medium-up-1 > .cell {
    width: 100%; }
  .medium-up-2 > .cell {
    width: 50%; }
  .medium-up-3 > .cell {
    width: 33.33333%; }
  .medium-up-4 > .cell {
    width: 25%; }
  .medium-up-5 > .cell {
    width: 20%; }
  .medium-up-6 > .cell {
    width: 16.66667%; }
  .medium-up-7 > .cell {
    width: 14.28571%; }
  .medium-up-8 > .cell {
    width: 12.5%; } }

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%; }
  .large-up-2 > .cell {
    width: 50%; }
  .large-up-3 > .cell {
    width: 33.33333%; }
  .large-up-4 > .cell {
    width: 25%; }
  .large-up-5 > .cell {
    width: 20%; }
  .large-up-6 > .cell {
    width: 16.66667%; }
  .large-up-7 > .cell {
    width: 14.28571%; }
  .large-up-8 > .cell {
    width: 12.5%; } }

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 1.12994rem); }

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 1.12994rem); }

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 1.12994rem); }

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 1.12994rem); }

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 1.12994rem); }

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 1.12994rem); }

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 1.12994rem); }

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 1.12994rem); }

@media print, screen and (min-width: 40em) {
  .grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.80791rem); }
  .grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.80791rem); }
  .grid-margin-x.small-up-3 > .cell {
    width: calc(33.33333% - 1.80791rem); }
  .grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.80791rem); }
  .grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.80791rem); }
  .grid-margin-x.small-up-6 > .cell {
    width: calc(16.66667% - 1.80791rem); }
  .grid-margin-x.small-up-7 > .cell {
    width: calc(14.28571% - 1.80791rem); }
  .grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.80791rem); }
  .grid-margin-x.medium-up-1 > .cell {
    width: calc(100% - 1.80791rem); }
  .grid-margin-x.medium-up-2 > .cell {
    width: calc(50% - 1.80791rem); }
  .grid-margin-x.medium-up-3 > .cell {
    width: calc(33.33333% - 1.80791rem); }
  .grid-margin-x.medium-up-4 > .cell {
    width: calc(25% - 1.80791rem); }
  .grid-margin-x.medium-up-5 > .cell {
    width: calc(20% - 1.80791rem); }
  .grid-margin-x.medium-up-6 > .cell {
    width: calc(16.66667% - 1.80791rem); }
  .grid-margin-x.medium-up-7 > .cell {
    width: calc(14.28571% - 1.80791rem); }
  .grid-margin-x.medium-up-8 > .cell {
    width: calc(12.5% - 1.80791rem); } }

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: calc(100% - 1.80791rem); }
  .grid-margin-x.large-up-2 > .cell {
    width: calc(50% - 1.80791rem); }
  .grid-margin-x.large-up-3 > .cell {
    width: calc(33.33333% - 1.80791rem); }
  .grid-margin-x.large-up-4 > .cell {
    width: calc(25% - 1.80791rem); }
  .grid-margin-x.large-up-5 > .cell {
    width: calc(20% - 1.80791rem); }
  .grid-margin-x.large-up-6 > .cell {
    width: calc(16.66667% - 1.80791rem); }
  .grid-margin-x.large-up-7 > .cell {
    width: calc(14.28571% - 1.80791rem); }
  .grid-margin-x.large-up-8 > .cell {
    width: calc(12.5% - 1.80791rem); } }

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0; }
  .small-margin-collapse > .small-1 {
    width: 8.33333%; }
  .small-margin-collapse > .small-2 {
    width: 16.66667%; }
  .small-margin-collapse > .small-3 {
    width: 25%; }
  .small-margin-collapse > .small-4 {
    width: 33.33333%; }
  .small-margin-collapse > .small-5 {
    width: 41.66667%; }
  .small-margin-collapse > .small-6 {
    width: 50%; }
  .small-margin-collapse > .small-7 {
    width: 58.33333%; }
  .small-margin-collapse > .small-8 {
    width: 66.66667%; }
  .small-margin-collapse > .small-9 {
    width: 75%; }
  .small-margin-collapse > .small-10 {
    width: 83.33333%; }
  .small-margin-collapse > .small-11 {
    width: 91.66667%; }
  .small-margin-collapse > .small-12 {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .small-margin-collapse > .medium-1 {
      width: 8.33333%; }
    .small-margin-collapse > .medium-2 {
      width: 16.66667%; }
    .small-margin-collapse > .medium-3 {
      width: 25%; }
    .small-margin-collapse > .medium-4 {
      width: 33.33333%; }
    .small-margin-collapse > .medium-5 {
      width: 41.66667%; }
    .small-margin-collapse > .medium-6 {
      width: 50%; }
    .small-margin-collapse > .medium-7 {
      width: 58.33333%; }
    .small-margin-collapse > .medium-8 {
      width: 66.66667%; }
    .small-margin-collapse > .medium-9 {
      width: 75%; }
    .small-margin-collapse > .medium-10 {
      width: 83.33333%; }
    .small-margin-collapse > .medium-11 {
      width: 91.66667%; }
    .small-margin-collapse > .medium-12 {
      width: 100%; } }
  @media print, screen and (min-width: 64em) {
    .small-margin-collapse > .large-1 {
      width: 8.33333%; }
    .small-margin-collapse > .large-2 {
      width: 16.66667%; }
    .small-margin-collapse > .large-3 {
      width: 25%; }
    .small-margin-collapse > .large-4 {
      width: 33.33333%; }
    .small-margin-collapse > .large-5 {
      width: 41.66667%; }
    .small-margin-collapse > .large-6 {
      width: 50%; }
    .small-margin-collapse > .large-7 {
      width: 58.33333%; }
    .small-margin-collapse > .large-8 {
      width: 66.66667%; }
    .small-margin-collapse > .large-9 {
      width: 75%; }
    .small-margin-collapse > .large-10 {
      width: 83.33333%; }
    .small-margin-collapse > .large-11 {
      width: 91.66667%; }
    .small-margin-collapse > .large-12 {
      width: 100%; } }

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0; }
  .small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .small-3 {
    width: 25%; }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .small-6 {
    width: 50%; }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .small-9 {
    width: 75%; }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .medium-3 {
    width: 25%; }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .medium-6 {
    width: 50%; }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .medium-9 {
    width: 75%; }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%; }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%; }
  .medium-margin-collapse > .large-3 {
    width: 25%; }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%; }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%; }
  .medium-margin-collapse > .large-6 {
    width: 50%; }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%; }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%; }
  .medium-margin-collapse > .large-9 {
    width: 75%; }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%; }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%; }
  .medium-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 40em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .medium-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-margin-collapse > .cell {
      margin-right: 0;
      margin-left: 0; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%; }
  .large-margin-collapse > .small-2 {
    width: 16.66667%; }
  .large-margin-collapse > .small-3 {
    width: 25%; }
  .large-margin-collapse > .small-4 {
    width: 33.33333%; }
  .large-margin-collapse > .small-5 {
    width: 41.66667%; }
  .large-margin-collapse > .small-6 {
    width: 50%; }
  .large-margin-collapse > .small-7 {
    width: 58.33333%; }
  .large-margin-collapse > .small-8 {
    width: 66.66667%; }
  .large-margin-collapse > .small-9 {
    width: 75%; }
  .large-margin-collapse > .small-10 {
    width: 83.33333%; }
  .large-margin-collapse > .small-11 {
    width: 91.66667%; }
  .large-margin-collapse > .small-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%; }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%; }
  .large-margin-collapse > .medium-3 {
    width: 25%; }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%; }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%; }
  .large-margin-collapse > .medium-6 {
    width: 50%; }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%; }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%; }
  .large-margin-collapse > .medium-9 {
    width: 75%; }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%; }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%; }
  .large-margin-collapse > .medium-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%; }
  .large-margin-collapse > .large-2 {
    width: 16.66667%; }
  .large-margin-collapse > .large-3 {
    width: 25%; }
  .large-margin-collapse > .large-4 {
    width: 33.33333%; }
  .large-margin-collapse > .large-5 {
    width: 41.66667%; }
  .large-margin-collapse > .large-6 {
    width: 50%; }
  .large-margin-collapse > .large-7 {
    width: 58.33333%; }
  .large-margin-collapse > .large-8 {
    width: 66.66667%; }
  .large-margin-collapse > .large-9 {
    width: 75%; }
  .large-margin-collapse > .large-10 {
    width: 83.33333%; }
  .large-margin-collapse > .large-11 {
    width: 91.66667%; }
  .large-margin-collapse > .large-12 {
    width: 100%; } }

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0; }
    .large-padding-collapse > .cell {
      padding-right: 0;
      padding-left: 0; } }

.small-offset-0 {
  margin-left: 0%; }

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.56497rem); }

.small-offset-1 {
  margin-left: 8.33333%; }

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.56497rem); }

.small-offset-2 {
  margin-left: 16.66667%; }

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.56497rem); }

.small-offset-3 {
  margin-left: 25%; }

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.56497rem); }

.small-offset-4 {
  margin-left: 33.33333%; }

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.56497rem); }

.small-offset-5 {
  margin-left: 41.66667%; }

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.56497rem); }

.small-offset-6 {
  margin-left: 50%; }

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.56497rem); }

.small-offset-7 {
  margin-left: 58.33333%; }

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.56497rem); }

.small-offset-8 {
  margin-left: 66.66667%; }

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.56497rem); }

.small-offset-9 {
  margin-left: 75%; }

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.56497rem); }

.small-offset-10 {
  margin-left: 83.33333%; }

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.56497rem); }

.small-offset-11 {
  margin-left: 91.66667%; }

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.56497rem); }

@media print, screen and (min-width: 40em) {
  .medium-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0.90395rem); }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0.90395rem); }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0.90395rem); }
  .medium-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0.90395rem); }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0.90395rem); }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0.90395rem); }
  .medium-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0.90395rem); }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0.90395rem); }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0.90395rem); }
  .medium-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0.90395rem); }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0.90395rem); }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0.90395rem); } }

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%; }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0.90395rem); }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0.90395rem); }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0.90395rem); }
  .large-offset-3 {
    margin-left: 25%; }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0.90395rem); }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0.90395rem); }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0.90395rem); }
  .large-offset-6 {
    margin-left: 50%; }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0.90395rem); }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0.90395rem); }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0.90395rem); }
  .large-offset-9 {
    margin-left: 75%; }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0.90395rem); }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0.90395rem); }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0.90395rem); } }

.grid-y {
  display: flex;
  flex-flow: column nowrap; }
  .grid-y > .cell {
    height: auto;
    max-height: none; }
  .grid-y > .auto {
    height: auto; }
  .grid-y > .shrink {
    height: auto; }
  .grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex-basis: auto; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex-basis: auto; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex-basis: auto; } }
  .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
    flex: 0 0 auto; }
  .grid-y > .small-1 {
    height: 8.33333%; }
  .grid-y > .small-2 {
    height: 16.66667%; }
  .grid-y > .small-3 {
    height: 25%; }
  .grid-y > .small-4 {
    height: 33.33333%; }
  .grid-y > .small-5 {
    height: 41.66667%; }
  .grid-y > .small-6 {
    height: 50%; }
  .grid-y > .small-7 {
    height: 58.33333%; }
  .grid-y > .small-8 {
    height: 66.66667%; }
  .grid-y > .small-9 {
    height: 75%; }
  .grid-y > .small-10 {
    height: 83.33333%; }
  .grid-y > .small-11 {
    height: 91.66667%; }
  .grid-y > .small-12 {
    height: 100%; }
  @media print, screen and (min-width: 40em) {
    .grid-y > .medium-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .medium-shrink, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
      flex: 0 0 auto; }
    .grid-y > .medium-shrink {
      height: auto; }
    .grid-y > .medium-1 {
      height: 8.33333%; }
    .grid-y > .medium-2 {
      height: 16.66667%; }
    .grid-y > .medium-3 {
      height: 25%; }
    .grid-y > .medium-4 {
      height: 33.33333%; }
    .grid-y > .medium-5 {
      height: 41.66667%; }
    .grid-y > .medium-6 {
      height: 50%; }
    .grid-y > .medium-7 {
      height: 58.33333%; }
    .grid-y > .medium-8 {
      height: 66.66667%; }
    .grid-y > .medium-9 {
      height: 75%; }
    .grid-y > .medium-10 {
      height: 83.33333%; }
    .grid-y > .medium-11 {
      height: 91.66667%; }
    .grid-y > .medium-12 {
      height: 100%; } }
  @media print, screen and (min-width: 64em) {
    .grid-y > .large-auto {
      flex: 1 1 0px;
      height: auto; }
    .grid-y > .large-shrink, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
      flex: 0 0 auto; }
    .grid-y > .large-shrink {
      height: auto; }
    .grid-y > .large-1 {
      height: 8.33333%; }
    .grid-y > .large-2 {
      height: 16.66667%; }
    .grid-y > .large-3 {
      height: 25%; }
    .grid-y > .large-4 {
      height: 33.33333%; }
    .grid-y > .large-5 {
      height: 41.66667%; }
    .grid-y > .large-6 {
      height: 50%; }
    .grid-y > .large-7 {
      height: 58.33333%; }
    .grid-y > .large-8 {
      height: 66.66667%; }
    .grid-y > .large-9 {
      height: 75%; }
    .grid-y > .large-10 {
      height: 83.33333%; }
    .grid-y > .large-11 {
      height: 91.66667%; }
    .grid-y > .large-12 {
      height: 100%; } }

.grid-padding-y .grid-padding-y {
  margin-top: -0.56497rem;
  margin-bottom: -0.56497rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
      margin-top: -0.90395rem;
      margin-bottom: -0.90395rem; } }

.grid-padding-y > .cell {
  padding-top: 0.56497rem;
  padding-bottom: 0.56497rem; }
  @media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
      padding-top: 0.90395rem;
      padding-bottom: 0.90395rem; } }

.grid-margin-y {
  margin-top: -0.56497rem;
  margin-bottom: -0.56497rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.90395rem;
      margin-bottom: -0.90395rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.12994rem);
    margin-top: 0.56497rem;
    margin-bottom: 0.56497rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.80791rem);
      margin-top: 0.90395rem;
      margin-bottom: 0.90395rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.12994rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.12994rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.12994rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.12994rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.12994rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.12994rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.12994rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.12994rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.12994rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.12994rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.12994rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.12994rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.80791rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.80791rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.80791rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.80791rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.80791rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.80791rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.80791rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.80791rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.80791rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.80791rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.80791rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.80791rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.80791rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.80791rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.80791rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.80791rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.80791rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.80791rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.80791rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.80791rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.80791rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.80791rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.80791rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.80791rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.80791rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.80791rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.80791rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.80791rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.80791rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.80791rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.80791rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.80791rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.80791rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.80791rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.80791rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.80791rem); } }

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw; }

.cell .grid-frame {
  width: 100%; }

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%; }
  .cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap; }

@media print, screen and (min-width: 40em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .medium-grid-frame {
    width: 100%; }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .medium-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; } }

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw; }
  .cell .large-grid-frame {
    width: 100%; }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%; }
    .large-cell-block-container > .grid-x {
      max-height: 100%;
      flex-wrap: nowrap; }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; } }

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh; }

@media print, screen and (min-width: 40em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh; } }

.cell .grid-y.grid-frame {
  height: 100%; }

@media print, screen and (min-width: 40em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%; } }

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%; } }

.grid-margin-y {
  margin-top: -0.56497rem;
  margin-bottom: -0.56497rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y {
      margin-top: -0.90395rem;
      margin-bottom: -0.90395rem; } }
  .grid-margin-y > .cell {
    height: calc(100% - 1.12994rem);
    margin-top: 0.56497rem;
    margin-bottom: 0.56497rem; }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
      height: calc(100% - 1.80791rem);
      margin-top: 0.90395rem;
      margin-bottom: 0.90395rem; } }
  .grid-margin-y > .auto {
    height: auto; }
  .grid-margin-y > .shrink {
    height: auto; }
  .grid-margin-y > .small-1 {
    height: calc(8.33333% - 1.12994rem); }
  .grid-margin-y > .small-2 {
    height: calc(16.66667% - 1.12994rem); }
  .grid-margin-y > .small-3 {
    height: calc(25% - 1.12994rem); }
  .grid-margin-y > .small-4 {
    height: calc(33.33333% - 1.12994rem); }
  .grid-margin-y > .small-5 {
    height: calc(41.66667% - 1.12994rem); }
  .grid-margin-y > .small-6 {
    height: calc(50% - 1.12994rem); }
  .grid-margin-y > .small-7 {
    height: calc(58.33333% - 1.12994rem); }
  .grid-margin-y > .small-8 {
    height: calc(66.66667% - 1.12994rem); }
  .grid-margin-y > .small-9 {
    height: calc(75% - 1.12994rem); }
  .grid-margin-y > .small-10 {
    height: calc(83.33333% - 1.12994rem); }
  .grid-margin-y > .small-11 {
    height: calc(91.66667% - 1.12994rem); }
  .grid-margin-y > .small-12 {
    height: calc(100% - 1.12994rem); }
  @media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
      height: auto; }
    .grid-margin-y > .shrink {
      height: auto; }
    .grid-margin-y > .small-1 {
      height: calc(8.33333% - 1.80791rem); }
    .grid-margin-y > .small-2 {
      height: calc(16.66667% - 1.80791rem); }
    .grid-margin-y > .small-3 {
      height: calc(25% - 1.80791rem); }
    .grid-margin-y > .small-4 {
      height: calc(33.33333% - 1.80791rem); }
    .grid-margin-y > .small-5 {
      height: calc(41.66667% - 1.80791rem); }
    .grid-margin-y > .small-6 {
      height: calc(50% - 1.80791rem); }
    .grid-margin-y > .small-7 {
      height: calc(58.33333% - 1.80791rem); }
    .grid-margin-y > .small-8 {
      height: calc(66.66667% - 1.80791rem); }
    .grid-margin-y > .small-9 {
      height: calc(75% - 1.80791rem); }
    .grid-margin-y > .small-10 {
      height: calc(83.33333% - 1.80791rem); }
    .grid-margin-y > .small-11 {
      height: calc(91.66667% - 1.80791rem); }
    .grid-margin-y > .small-12 {
      height: calc(100% - 1.80791rem); }
    .grid-margin-y > .medium-auto {
      height: auto; }
    .grid-margin-y > .medium-shrink {
      height: auto; }
    .grid-margin-y > .medium-1 {
      height: calc(8.33333% - 1.80791rem); }
    .grid-margin-y > .medium-2 {
      height: calc(16.66667% - 1.80791rem); }
    .grid-margin-y > .medium-3 {
      height: calc(25% - 1.80791rem); }
    .grid-margin-y > .medium-4 {
      height: calc(33.33333% - 1.80791rem); }
    .grid-margin-y > .medium-5 {
      height: calc(41.66667% - 1.80791rem); }
    .grid-margin-y > .medium-6 {
      height: calc(50% - 1.80791rem); }
    .grid-margin-y > .medium-7 {
      height: calc(58.33333% - 1.80791rem); }
    .grid-margin-y > .medium-8 {
      height: calc(66.66667% - 1.80791rem); }
    .grid-margin-y > .medium-9 {
      height: calc(75% - 1.80791rem); }
    .grid-margin-y > .medium-10 {
      height: calc(83.33333% - 1.80791rem); }
    .grid-margin-y > .medium-11 {
      height: calc(91.66667% - 1.80791rem); }
    .grid-margin-y > .medium-12 {
      height: calc(100% - 1.80791rem); } }
  @media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
      height: auto; }
    .grid-margin-y > .large-shrink {
      height: auto; }
    .grid-margin-y > .large-1 {
      height: calc(8.33333% - 1.80791rem); }
    .grid-margin-y > .large-2 {
      height: calc(16.66667% - 1.80791rem); }
    .grid-margin-y > .large-3 {
      height: calc(25% - 1.80791rem); }
    .grid-margin-y > .large-4 {
      height: calc(33.33333% - 1.80791rem); }
    .grid-margin-y > .large-5 {
      height: calc(41.66667% - 1.80791rem); }
    .grid-margin-y > .large-6 {
      height: calc(50% - 1.80791rem); }
    .grid-margin-y > .large-7 {
      height: calc(58.33333% - 1.80791rem); }
    .grid-margin-y > .large-8 {
      height: calc(66.66667% - 1.80791rem); }
    .grid-margin-y > .large-9 {
      height: calc(75% - 1.80791rem); }
    .grid-margin-y > .large-10 {
      height: calc(83.33333% - 1.80791rem); }
    .grid-margin-y > .large-11 {
      height: calc(91.66667% - 1.80791rem); }
    .grid-margin-y > .large-12 {
      height: calc(100% - 1.80791rem); } }

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap; }
  [data-whatinput='mouse'] .menu li {
    outline: 0; }
  .menu a,
  .menu .button {
    line-height: 1;
    text-decoration: none;
    display: block;
    padding: none; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu input {
    display: inline-block; }
  .menu, .menu.horizontal {
    flex-wrap: wrap;
    flex-direction: row; }
  .menu.vertical {
    flex-wrap: nowrap;
    flex-direction: column; }
  .menu.expanded li {
    flex: 1 1 0px; }
  .menu.simple {
    align-items: center; }
    .menu.simple li + li {
      margin-left: 1rem; }
    .menu.simple a {
      padding: 0; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.medium-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.medium-expanded li {
      flex: 1 1 0px; }
    .menu.medium-simple li {
      flex: 1 1 0px; } }
  @media print, screen and (min-width: 64em) {
    .menu.large-horizontal {
      flex-wrap: wrap;
      flex-direction: row; }
    .menu.large-vertical {
      flex-wrap: nowrap;
      flex-direction: column; }
    .menu.large-expanded li {
      flex: 1 1 0px; }
    .menu.large-simple li {
      flex: 1 1 0px; } }
  .menu.nested {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.icons a {
    display: flex; }
  .menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
    display: flex; }
  .menu.icon-left li a {
    flex-flow: row nowrap; }
    .menu.icon-left li a img,
    .menu.icon-left li a i,
    .menu.icon-left li a svg {
      margin-right: 0.25rem; }
  .menu.icon-right li a {
    flex-flow: row nowrap; }
    .menu.icon-right li a img,
    .menu.icon-right li a i,
    .menu.icon-right li a svg {
      margin-left: 0.25rem; }
  .menu.icon-top li a {
    flex-flow: column nowrap; }
    .menu.icon-top li a img,
    .menu.icon-top li a i,
    .menu.icon-top li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu.icon-bottom li a {
    flex-flow: column nowrap; }
    .menu.icon-bottom li a img,
    .menu.icon-bottom li a i,
    .menu.icon-bottom li a svg {
      align-self: stretch;
      margin-bottom: 0.25rem;
      text-align: center; }
  .menu .is-active > a {
    background: #131D4C;
    color: #ffffff; }
  .menu .active > a {
    background: #131D4C;
    color: #ffffff; }
  .menu.align-left {
    justify-content: flex-start; }
  .menu.align-right li {
    display: flex;
    justify-content: flex-end; }
    .menu.align-right li .submenu li {
      justify-content: flex-start; }
  .menu.align-right.vertical li {
    display: block;
    text-align: right; }
    .menu.align-right.vertical li .submenu li {
      text-align: right; }
  .menu.align-right .nested {
    margin-right: 1rem;
    margin-left: 0; }
  .menu.align-center li {
    display: flex;
    justify-content: center; }
    .menu.align-center li .submenu li {
      justify-content: flex-start; }
  .menu .menu-text {
    padding: 0.7rem 1rem;
    font-weight: bold;
    line-height: 1;
    color: inherit; }

.menu-centered > .menu {
  justify-content: center; 
}

.menu-centered > .menu li {
  display: flex;
  justify-content: center; 
}

.menu-centered > .menu li .submenu li {
  justify-content: flex-start; 
}

.list-base a {
  display: inline-flex;
  text-decoration: none; 
}

a {
  line-height: inherit;
  height: 23px;
  color: #FF5F1E;
}

a.link {
  line-height: inherit;
}

.footer-item-list__item a {
  color: inherit;
  line-height: inherit;
}

blockquote, dd, div, dl, dt, form, h1, h2, h3, h4, h5, h6, li, ol, p, pre, td, th, ul {
  margin: 0;
  padding: 0;
}

.footer-column-title::after {
  content: '';
  width: 75.65%;
  height: 1px
;
  background-color: rgba(255,95,30,.5);
  position: absolute;
  bottom: 0;
  left: 0;
}

.pre-footer {
  background-color: #131D4C;
  padding-top: 70px;
  padding-bottom: 36px;
  clip-path: polygon(0 65px, 31% 0, 100% 69px, 100% 100%, 0 100%);
  margin-bottom: -1px;
  margin-top: 48px; 
}

@media print, screen and (min-width: 40em) {
  .pre-footer {
    margin-top: 84px;
    padding-bottom: 106px;
    padding-top: 90px;
    clip-path: polygon(0 95px, 29.1% 0, 100% 99px, 100% 100%, 0 100%); 
  } 
}

@media print, screen and (min-width: 64em) {
  .pre-footer {
    padding-top: 127px;
    clip-path: polygon(0 165px, 29.1% 0, 100% 169px, 100% 100%, 0 100%);
  }
}

.pre-footer__image {
  margin-left: -12px;
  padding-right: 2px;
  margin-bottom: 20px;
  width: 140px;
  height: 140px; 
}

@media print, screen and (min-width: 40em) {
  .pre-footer__image {
    margin-bottom: 0; 
  }  
}
.pre-footer__text {
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
  padding-right: 16px;
  margin-bottom: 20px; 
}

@media print, screen and (min-width: 40em) {
  .pre-footer__text {
    margin-bottom: -17px;
    font-size: 34px;
    line-height: 42px; 
  } 
}

.pre-footer__text p {
  line-height: inherit;
  margin-bottom: 0; 
}

.pre-footer__text .button {
  margin-bottom: 0;
  margin-left: 30px;
  white-space: nowrap; 
}

.pre-footer-content {
  background-color: #131D4C; 
}

.pre-footer-content__content {
  padding: 20px 0;
  color: white; 
}

.header {
  padding: 26px 30px 26px;
  background-color: #131D4C;
  z-index: 1;
}

.header, .header-contacts, .header__nav {
  position: relative;
}

@media print, screen and (min-width: 40em) {
  .header {
    padding: 30px 16px 29px; 
  } 
}
  .header__inner {
    max-width: 1212px;
    margin-left: auto;
    margin-right: auto; 
  }
  .header__nav {
    position: relative; 
  }

  .header-links__item:not(:last-child) {
    margin-right: 8px; 
  }
  
  .header-links a {
    font-size: 13px;
    color: #4759b2;
    text-decoration: underline;
    line-height: 1; 
  }
  
  .header-contacts {
    font-size: 13px;
    color: #717794;
    margin-left: 10px;
    padding-left: 11px;
    position: relative; 
  }
    
@media print, screen and (min-width: 64em) {
  .header-contacts {
    margin-left: 16px;
    padding-left: 17px; 
  } 
}
.header-contacts:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 1px;
  height: 16px;
  background-color: rgba(137, 142, 166, 0.2); 
}
.header-contacts__link {
  font-weight: 600;
  font-size: 13px;
  color: #FF5F1E; 
}
.header-contacts__link:hover {
  color: #cc4c18;
  text-decoration: underline; 
}

@media print, screen and (max-width: 63.99875em) {
  #SearchForm_SearchForm {
    display: none; 
  } 
}

@media print, screen and (min-width: 40em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto; 
  } 
}

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto; 
  } 
}

@media print, screen and (max-width: 63.99875em) {
  .main-nav #SearchForm_SearchForm {
    display: block;
    width: 100%; 
  }
  .main-nav #SearchForm_SearchForm .field {
    width: 100%;
    margin-left: 20px;
    margin-top: 8px; 
  }
  .main-nav #SearchForm_SearchForm input[type=submit] {
    display: block;
    background-color: transparent;
    color: white;
    border: none;
    padding-right: 16px; 
  }
  .main-nav #SearchForm_SearchForm input[type=submit]:active {
    opacity: 0.6; 
  } 
}

@media print, screen and (min-width: 40em) {
  .logo img {
    width: 150px; 
  } 
}
@media print, screen and (min-width: 64em) {
  .logo img {
    width: 176px; 
  } 
}